
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import { makeStakingIdValidator } from "@/shared/utils/validators/adaValidators";
import InputText from "@/shared/components/Forms/InputText.vue";
import SubmitButton from "@/shared/components/Buttons/SubmitButton.vue";
import routePaths from "@/web/router/routePaths";

export default defineComponent({
  components: { FullPageCardLayout, FormGroup, InputText, SubmitButton },
  props: { loading: Boolean },
  emits: ["on-submit"],
  setup(_props, { emit }) {
    const { t } = useI18n();

    const formForgotPasswordState = reactive({
      stakingKeyHash: "",
    });

    const handleSubmit = async () => {
      emit("on-submit", formForgotPasswordState.stakingKeyHash);
    };

    return { t, formForgotPasswordState, handleSubmit, routePaths };
  },
  methods: { makeRequiredRule, makeStakingIdValidator },
});
