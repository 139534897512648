import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_SubmitButton = _resolveComponent("SubmitButton")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_FullPageCardLayout = _resolveComponent("FullPageCardLayout")!

  return (_openBlock(), _createBlock(_component_FullPageCardLayout, {
    class: "forgot-password-step1",
    title: _ctx.t('Password recovery')
  }, {
    "header-right": _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: _ctx.routePaths.login
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Back")), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_FormGroup, {
        class: "forgot-password-step1__form",
        model: _ctx.formForgotPasswordState,
        loading: _ctx.loading,
        onFinish: _ctx.handleSubmit
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_typography_title, {
            level: 4,
            class: "font-normal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Step 1")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_InputText, {
            value: _ctx.formForgotPasswordState.stakingKeyHash,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formForgotPasswordState.stakingKeyHash) = $event)),
            label: _ctx.t('Enter your staking ID'),
            name: "stakingKeyHash",
            placeholder: "ba12d7cfa46e187426a72655fc26e632f...",
            rules: [
          _ctx.makeRequiredRule(_ctx.t('Staking ID is required')),
          _ctx.makeStakingIdValidator(),
        ]
          }, null, 8, ["value", "label", "rules"]),
          _createVNode(_component_SubmitButton, { loading: _ctx.loading }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Continue")), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _: 1
      }, 8, ["model", "loading", "onFinish"])
    ]),
    _: 1
  }, 8, ["title"]))
}