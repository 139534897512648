import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createSlots as _createSlots, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "confirm-wallet__body" }
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopHeaderLogout = _resolveComponent("TopHeaderLogout")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_statistic_countdown = _resolveComponent("a-statistic-countdown")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_TitleValue = _resolveComponent("TitleValue")!
  const _component_SubmitButton = _resolveComponent("SubmitButton")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_FullPageCardLayout = _resolveComponent("FullPageCardLayout")!

  return (_openBlock(), _createBlock(_component_FullPageCardLayout, { class: "confirm-wallet" }, {
    "header-right": _withCtx(() => [
      _createVNode(_component_TopHeaderLogout)
    ]),
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_typography_title, {
          class: "m-0 full-page-card-layout__title",
          level: 2
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Confirmation")), 1)
          ]),
          _: 1
        }),
        (_ctx.walletDetail?.walletConfirmationAttempt.expirationDate)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createElementVNode("img", {
                src: _ctx.ClockIcon,
                class: "mb-2"
              }, null, 8, _hoisted_3),
              _createVNode(_component_a_statistic_countdown, {
                class: "inline-block font-normal ml-2",
                value: _ctx.walletDetail?.walletConfirmationAttempt.expirationDate,
                format: "mm:ss",
                onFinish: _ctx.onTimeout
              }, null, 8, ["value", "onFinish"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.stepText)
        ? (_openBlock(), _createBlock(_component_a_typography_title, {
            key: 0,
            level: 4,
            class: "font-normal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.stepText), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t(
            "To confirm your wallet, pay a small amount to {merchantBrand} during 30 minutes",
            {
              merchantBrand: _ctx.config.merchantBrand,
            }
          )), 1)
          ]),
          _: 1
        }),
        (_ctx.confirmStatus != _ctx.ConfirmationStatus.TIMEOUT)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_TitleValue, {
                title: _ctx.t('Amount'),
                value: _ctx.parsedConfirmationAmount,
                titleProps: { class: 'uppercase' }
              }, _createSlots({ _: 2 }, [
                (_ctx.walletDetailLoading)
                  ? {
                      name: "value",
                      fn: _withCtx(() => [
                        _createVNode(_component_a_skeleton, {
                          active: "",
                          title: false,
                          paragraph: { rows: 1, width: '7rem' }
                        })
                      ])
                    }
                  : undefined
              ]), 1032, ["title", "value"]),
              _createVNode(_component_TitleValue, {
                title: _ctx.t('Your Own Wallet'),
                value: _ctx.maskWalletAddress(_ctx.walletAddress),
                class: "mb-8",
                titleProps: { class: 'uppercase' },
                valueProps: {
            copyable: _ctx.walletAddress
              ? {
                  text: _ctx.walletAddress,
                }
              : false,
          }
              }, _createSlots({ _: 2 }, [
                (_ctx.walletDetailLoading)
                  ? {
                      name: "value",
                      fn: _withCtx(() => [
                        _createVNode(_component_a_skeleton, {
                          active: "",
                          title: false,
                          paragraph: { rows: 1, width: '7rem' }
                        })
                      ])
                    }
                  : undefined
              ]), 1032, ["title", "value", "valueProps"])
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.confirmStatus == _ctx.ConfirmationStatus.REGISTERED)
          ? (_openBlock(), _createBlock(_component_SubmitButton, {
              key: 1,
              class: "confirm-wallet__send-money",
              onClick: _ctx.handleSubmit,
              block: "",
              loading: _ctx.confirmWalletLoading,
              disabled: _ctx.walletDetailLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("I have sent the money")), 1)
              ]),
              _: 1
            }, 8, ["onClick", "loading", "disabled"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createVNode(_component_a_alert, {
                message: _ctx.alertMessage,
                type: 
            _ctx.confirmStatus == _ctx.ConfirmationStatus.SUCCESS ? 'success' : 'error'
          ,
                class: "w-full p-3 whitespace-pre-wrap",
                showIcon: ""
              }, {
                icon: _withCtx(() => [
                  _createElementVNode("img", {
                    src: 
                _ctx.confirmStatus == _ctx.ConfirmationStatus.SUCCESS
                  ? _ctx.SuccessIcon
                  : _ctx.WarningIcon
              
                  }, null, 8, _hoisted_5)
                ]),
                _: 1
              }, 8, ["message", "type"]),
              (_ctx.confirmStatus == _ctx.ConfirmationStatus.SUCCESS)
                ? (_openBlock(), _createBlock(_component_SubmitButton, {
                    key: 0,
                    class: "confirm-wallet__button"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Continue")), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_a_button, {
                    key: 1,
                    class: "confirm-wallet__button ant-btn-mobile-block",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleRetry())),
                    loading: _ctx.walletDetailLoading
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Retry")), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"]))
            ], 64))
      ])
    ]),
    _: 1
  }))
}