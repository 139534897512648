import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "forgot-password" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForgotPasswordStep1StakingKey = _resolveComponent("ForgotPasswordStep1StakingKey")!
  const _component_ForgotPasswordStep2ConfirmWallet = _resolveComponent("ForgotPasswordStep2ConfirmWallet")!
  const _component_ForgotPasswordStep3NewPassword = _resolveComponent("ForgotPasswordStep3NewPassword")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (
        _ctx.forgotPasswordPendingAction === _ctx.ForgotPasswordAction.STAKING_KEY_INPUT
      )
      ? (_openBlock(), _createBlock(_component_ForgotPasswordStep1StakingKey, {
          key: 0,
          loading: _ctx.initForgotPasswordLoading,
          onOnSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleInitForgotPassword($event)))
        }, null, 8, ["loading"]))
      : (
        _ctx.forgotPasswordPendingAction === _ctx.ForgotPasswordAction.WALLET_CONFIRMATION
      )
        ? (_openBlock(), _createBlock(_component_ForgotPasswordStep2ConfirmWallet, {
            key: 1,
            confirmStatus: _ctx.confirmStatus,
            "onUpdate:confirmStatus": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.confirmStatus) = $event)),
            walletDetail: _ctx.walletConfirmationDetail,
            walletDetailLoading: _ctx.walletConfirmationDetailLoading,
            confirmWalletLoading: _ctx.confirmWalletLoading,
            onOnSubmit: _ctx.handleConfirmWallet,
            onOnRetry: _ctx.handleRefetchWalletDetails
          }, null, 8, ["confirmStatus", "walletDetail", "walletDetailLoading", "confirmWalletLoading", "onOnSubmit", "onOnRetry"]))
        : (
        _ctx.forgotPasswordPendingAction === _ctx.ForgotPasswordAction.SET_PASSWORD
      )
          ? (_openBlock(), _createBlock(_component_ForgotPasswordStep3NewPassword, {
              key: 2,
              loading: _ctx.setNewPasswordLoading,
              onOnSubmit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleSetNewPassword($event)))
            }, null, 8, ["loading"]))
          : _createCommentVNode("", true)
  ]))
}