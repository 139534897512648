import { computed, Ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { walletConfirmationDetailGql } from "@/api/wallet/walletConfirmationDetail";
import {
  WalletConfirmationDetail,
  WalletConfirmationDetailVariables,
  WalletConfirmationDetail_walletConfirmationDetail_WalletConfirmationDetail,
} from "@/api/wallet/__generated__/WalletConfirmationDetail";
import { confirmWalletGql } from "@/api/wallet/confirmWallet";
import {
  ConfirmWallet,
  ConfirmWalletVariables,
  ConfirmWallet_confirmWallet_User,
} from "@/api/wallet/__generated__/ConfirmWallet";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { apiErrorCodes } from "@/shared/utils/constants";
import isEmpty from "lodash/isEmpty";
import { makeToast } from "@/shared/utils/toast";
import { i18nTranslate } from "@/plugins/i18n";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import {
  ConfirmWalletInput,
  UserPendingAction,
} from "../../../../__generated__/globalTypes";
import find from "lodash/find";
import { compactObject } from "@/shared/utils/object";

/**
 * each property is a reference to the actual value
 */
type WalletConfirmationDetailInputParam = {
  id: Ref<string>;
  recoveryUuid: Ref<string>;
};

export const useWalletConfirmationDetail = (
  walletConfirmationDetailInput: WalletConfirmationDetailInputParam | null,
  fetchWalletDetailOnMount = true
) => {
  /**
   * GET details
   */
  const {
    loading: walletDetailLoading,
    result: walletConfirmationDetailResult,
    refetch: handleRefetchWalletConfirmationDetail,
  } = useQuery<WalletConfirmationDetail, WalletConfirmationDetailVariables>(
    walletConfirmationDetailGql,
    () => ({
      ...(walletConfirmationDetailInput?.recoveryUuid.value
        ? {
            input: {
              recoveryUuid: walletConfirmationDetailInput?.recoveryUuid.value,
            },
          }
        : {}),
    }),
    () => ({
      fetchPolicy: "no-cache",
      enabled:
        fetchWalletDetailOnMount ||
        !!walletConfirmationDetailInput?.recoveryUuid.value,
    })
  );
  const walletDetail = computed(() => {
    const parsedResponse =
      parseGqlResponse<WalletConfirmationDetail_walletConfirmationDetail_WalletConfirmationDetail>(
        "WalletConfirmationDetail",
        walletConfirmationDetailResult.value
      );

    return parsedResponse.data;
  });
  /**
   * END GET wallet confirmation details
   */

  /**
   * Confirm Wallet
   */
  const confirmWalletMutation = useCustomMutation<
    ConfirmWallet,
    ConfirmWalletVariables
  >(confirmWalletGql);
  const handleConfirmWallet = async ({
    walletConfirmationAttemptId,
    recoveryUuid = null,
  }: ConfirmWalletInput) => {
    const confirmWalletResponse = await confirmWalletMutation.mutate({
      input: <ConfirmWalletInput>(
        compactObject({ walletConfirmationAttemptId, recoveryUuid })
      ),
    });
    const parsedResponse = parseGqlResponse<ConfirmWallet_confirmWallet_User>(
      "User",
      confirmWalletResponse,
      apiErrorCodes.INTERNAL_ERROR
    );

    console.log("handleConfirmWallet:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !confirmWalletResponse) {
      //check if ConfirmationExpiredError
      if (
        find(parsedResponse?.error?.errors, [
          "code",
          apiErrorCodes.CONFIRMATION_EXPIRED,
        ])
      ) {
        /**
         * Handle refetch when CONFIRMATION_EXPIRED is returned
         */
        handleRefetchWalletConfirmationDetail();

        makeToast(
          "error",
          i18nTranslate("Error"),
          i18nTranslate(
            "Confirmation code is now expired. Please create a new confirmation"
          )
        );
      }
      throw new Error("Wallet is not confirmed");
    }

    // also save to register store
    registrationSimpleStore.pendingAction = <UserPendingAction>(
      parsedResponse.data?.pendingAction
    );

    return parsedResponse.data;
  };
  /**
   * END Confirm Wallet
   */

  return {
    walletDetailLoading,
    walletDetail,
    handleRefetchWalletConfirmationDetail,
    handleConfirmWallet,
    confirmWalletLoading: computed(() => confirmWalletMutation.loading.value),
  };
};
