/**
 * WalletDetails
 * confirmationAmount is in Lovelace and you need to convert it to ADA (by dividing it by 1,000,000)
 */
export const convertLovelaceToAda = (lovelaceAmount: number) => {
  return (
    new Intl.NumberFormat("en", { maximumFractionDigits: 6 }).format(
      lovelaceAmount / 1000000
    ) + " ADA"
  );
};
