import gql from "graphql-tag";

export const walletConfirmationDetailGql = gql`
  query WalletConfirmationDetail($input: WalletConfirmationDetailInput) {
    walletConfirmationDetail(input: $input) {
      ... on WalletConfirmationDetail {
        wallet {
          id
          created
          modified
          stakingKeyHash
          mainAddress
        }
        walletConfirmationAttempt {
          id
          confirmationAmount
          expirationDate
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
