
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import ConfirmWallet from "@/shared/components/ConfirmWalletUI.vue";

export default defineComponent({
  emits: ["on-submit", "on-retry"],
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: {},
  components: { ConfirmWallet },
});
