
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";
import { registrationSimpleStore } from "@/web/store/registrationStore";
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import TopHeaderLogout from "@/web/views/Register/TopHeaderLogout.vue";
import TitleValue from "@/shared/components/TitleValue.vue";
import ClockIcon from "@/assets/clock.svg";
import WarningIcon from "@/assets/warning-icon.svg";
import SuccessIcon from "@/assets/success-icon.svg";
import SubmitButton from "@/shared/components/Buttons/SubmitButton.vue";
import { convertLovelaceToAda } from "@/web/utils/walletUtils";
import { maskWalletAddress } from "@/shared/utils/stringHelper";
import { useVModel } from "vue-composable";
import { WalletConfirmationDetail_walletConfirmationDetail_WalletConfirmationDetail } from "@/api/wallet/__generated__/WalletConfirmationDetail";

export enum ConfirmationStatus {
  REGISTERED = "REGISTERED",
  TIMEOUT = "TIMEOUT",
  INCORRECT_AMOUNT = "INCORRECT_AMOUNT",
  SUCCESS = "SUCCESS",
}

export default defineComponent({
  emits: ["on-timeout", "on-retry", "on-submit"],
  props: {
    confirmStatus: {
      type: String as PropType<ConfirmationStatus>,
      default: ConfirmationStatus.REGISTERED,
    },
    walletDetail: {
      type: Object as PropType<WalletConfirmationDetail_walletConfirmationDetail_WalletConfirmationDetail>,
    },
    walletDetailLoading: Boolean,
    confirmWalletLoading: Boolean,
    stepText: String,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const confirmStatus = useVModel(props, "confirmStatus");

    const onTimeout = () => {
      emit("on-timeout");
      // TODO: update when timeout
      console.log("timeout");
      // If status is success, no need to set the timeout error
      if (confirmStatus.value != ConfirmationStatus.SUCCESS) {
        confirmStatus.value = ConfirmationStatus.TIMEOUT;
      }
    };

    const parsedConfirmationAmount = computed(() =>
      convertLovelaceToAda(
        props.walletDetail?.walletConfirmationAttempt?.confirmationAmount
      )
    );

    const handleSubmit = async () => {
      emit("on-submit");
    };

    const handleRetry = async () => {
      emit("on-retry");
    };

    return {
      t,
      config,
      onTimeout,
      registrationSimpleStore,
      parsedConfirmationAmount,
      handleSubmit,
      handleRetry,
      ClockIcon,
      WarningIcon,
      SuccessIcon,
      ConfirmationStatus,
      walletAddress: computed(
        () => props.walletDetail?.wallet.mainAddress as string
      ),
      alertMessage: computed(
        () =>
          ({
            [ConfirmationStatus.TIMEOUT]: t(
              "Time is over! We didn’t receive your crypto"
            ),
            [ConfirmationStatus.INCORRECT_AMOUNT]: t(
              "Error! We have received incorrect ADA amount"
            ),
            [ConfirmationStatus.SUCCESS]: t(
              "We have received your crypto!\nWallet has been confirmed"
            ),
          }[confirmStatus.value] || "")
      ),
    };
  },
  methods: {
    maskWalletAddress,
  },
  components: { FullPageCardLayout, TitleValue, TopHeaderLogout, SubmitButton },
});
