import gql from "graphql-tag";

export const confirmWalletGql = gql`
  mutation ConfirmWallet($input: ConfirmWalletInput!) {
    confirmWallet(input: $input) {
      ... on User {
        id
        username
        created
        modified
        wallet {
          id
          created
          modified
          stakingKeyHash
          mainAddress
        }
        name
        avatar40
        avatar80
        avatar400
        pendingAction
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
