
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
import FullPageCardLayout from "@/shared/components/Layouts/FullPageCardLayout.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import { makePasswordRule } from "@/shared/utils/validators/passwordValidator";
import InputPassword from "@/shared/components/Forms/InputPassword.vue";
import SubmitButton from "@/shared/components/Buttons/SubmitButton.vue";

export default defineComponent({
  emits: ["on-submit"],
  props: { loading: Boolean },
  setup(_props, { emit }) {
    const { t } = useI18n();

    const formForgotPasswordState = reactive({
      newPassword: "",
    });

    const handleSubmit = async () => {
      emit("on-submit", formForgotPasswordState.newPassword);
    };

    return { t, formForgotPasswordState, handleSubmit };
  },
  methods: { makeRequiredRule, makePasswordRule },
  components: { FullPageCardLayout, FormGroup, InputPassword, SubmitButton },
});
