import gql from "graphql-tag";

export const initiateAccountRecoveryGql = gql`
  mutation InitiateAccountRecovery($input: InitiateAccountRecoveryInput!) {
    initiateAccountRecovery(input: $input) {
      ... on AccountRecoveryAttempt {
        id
        recoveryUuid
        toRecover
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
