import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ConfirmWallet = _resolveComponent("ConfirmWallet")!

  return (_openBlock(), _createBlock(_component_ConfirmWallet, _mergeProps(_ctx.$attrs, {
    stepText: _ctx.t('Step 2'),
    onOnSubmit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-submit', $event))),
    onOnRetry: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-retry', $event)))
  }), null, 16, ["stepText"]))
}