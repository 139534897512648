
import {
  useForgotPassword,
  ForgotPasswordAction,
} from "@/shared/composables/useForgotPassword";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import ForgotPasswordStep1StakingKey from "@/web/views/ForgotPassword/ForgotPasswordStep1StakingKey.vue";
import ForgotPasswordStep2ConfirmWallet from "@/web/views/ForgotPassword/ForgotPasswordStep2ConfirmWallet.vue";
import ForgotPasswordStep3NewPassword from "@/web/views/ForgotPassword/ForgotPasswordStep3NewPassword.vue";
import { ToRecover } from "../../../../__generated__/globalTypes";
import routeNames from "@/shared/router/routeNames";
import { message } from "ant-design-vue";
import { ConfirmationStatus } from "@/shared/components/ConfirmWalletUI.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    const confirmStatus = ref(ConfirmationStatus.REGISTERED);

    const {
      forgotPasswordPendingAction,
      initForgotPassword,
      initForgotPasswordLoading,
      confirmWallet,
      confirmWalletLoading,
      walletConfirmationDetail,
      walletConfirmationDetailLoading,
      fetchWalletConfirmationDetail,
      setNewPassword,
      setNewPasswordLoading,
    } = useForgotPassword();

    const handleInitForgotPassword = async (stakingKeyHash) => {
      try {
        await initForgotPassword({
          stakingKeyHash,
          toRecover: ToRecover.PASSWORD,
        });
      } catch (error) {
        console.log("handleInitForgotPassword:error", error);
      }
    };

    const handleConfirmWallet = async () => {
      try {
        await confirmWallet();
        confirmStatus.value = ConfirmationStatus.SUCCESS;
      } catch (error) {
        console.log("handleInitForgotPassword:error", error);
      }
    };

    const handleRefetchWalletDetails = async () => {
      try {
        await fetchWalletConfirmationDetail();
        // reset to initial state after clicking retry button
        confirmStatus.value = ConfirmationStatus.REGISTERED;
      } catch (error) {
        console.log("handleRefetchWalletDetails:error", error);
      }
    };

    const handleSetNewPassword = async (newPassword) => {
      try {
        await setNewPassword({ newPassword });
        message.info(
          t(
            "Your password has been changed successfully. Use your new password to log in."
          )
        );
        router.push({ name: routeNames.login });
      } catch (error) {
        console.log("handleInitForgotPassword:error", error);
      }
    };

    return {
      t,
      ForgotPasswordAction,
      forgotPasswordPendingAction,
      handleInitForgotPassword,
      initForgotPasswordLoading,
      handleConfirmWallet,
      confirmWalletLoading,
      confirmStatus,
      handleRefetchWalletDetails,
      walletConfirmationDetail,
      walletConfirmationDetailLoading,
      handleSetNewPassword,
      setNewPasswordLoading,
    };
  },
  components: {
    ForgotPasswordStep1StakingKey,
    ForgotPasswordStep2ConfirmWallet,
    ForgotPasswordStep3NewPassword,
  },
});
