import gql from "graphql-tag";

export const setPasswordGql = gql`
  mutation SetPassword($input: SetPasswordInput!) {
    setPassword(input: $input) {
      ... on GenericSuccess {
        success
        message
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
