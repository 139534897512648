import { reactive, ref, toRefs } from "vue";
import { initiateAccountRecoveryGql } from "@/api/forgotPassword/initiateAccountRecovery";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import {
  InitiateAccountRecovery,
  InitiateAccountRecoveryVariables,
  InitiateAccountRecovery_initiateAccountRecovery_AccountRecoveryAttempt,
} from "@/api/forgotPassword/__generated__/InitiateAccountRecovery";
import { parseGqlResponse } from "../utils/graphql/responseParser";
import { apiErrorCodes } from "../utils/constants";
import isEmpty from "lodash/isEmpty";
import { useWalletConfirmationDetail } from "./wallet/useWalletConfirmationDetail";
import { setPasswordGql } from "@/api/forgotPassword/setPassword";
import {
  SetPassword,
  SetPasswordVariables,
  SetPassword_setPassword_GenericSuccess,
} from "@/api/forgotPassword/__generated__/SetPassword";
import { SetPasswordInput } from "__generated__/globalTypes";

export enum ForgotPasswordAction {
  STAKING_KEY_INPUT = "STAKING_KEY_INPUT",
  WALLET_CONFIRMATION = "WALLET_CONFIRMATION",
  TWO_FACTOR_AUTHENTICATION = "TWO_FACTOR_AUTHENTICATION",
  SET_PASSWORD = "SET_PASSWORD",
}

export const useForgotPassword = () => {
  const forgotPasswordState = reactive({
    id: "",
    recoveryUuid: "",
    walletConfirmationAttemptId: "",
  });
  const forgotPasswordPendingAction = ref(
    ForgotPasswordAction.STAKING_KEY_INPUT // staking key input is the initial state
  );

  const {
    walletDetail: walletConfirmationDetail,
    handleRefetchWalletConfirmationDetail,
    walletDetailLoading: walletConfirmationDetailLoading,
    handleConfirmWallet,
    confirmWalletLoading,
  } = useWalletConfirmationDetail(toRefs(forgotPasswordState), false);

  /**
   * INITIATE ACCOUNT RECOVERY
   */
  const {
    mutate: initiateAccountRecoveryMutate,
    loading: initForgotPasswordLoading,
  } = useCustomMutation<
    InitiateAccountRecovery,
    InitiateAccountRecoveryVariables
  >(initiateAccountRecoveryGql);

  const initForgotPassword = async ({ stakingKeyHash, toRecover }) => {
    const initResponse = await initiateAccountRecoveryMutate({
      input: { stakingKeyHash, toRecover },
    });

    const parsedResponse =
      parseGqlResponse<InitiateAccountRecovery_initiateAccountRecovery_AccountRecoveryAttempt>(
        "AccountRecoveryAttempt",
        initResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("initForgotPassword:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !initResponse) {
      throw new Error("InitForgotPassword error");
    }

    forgotPasswordState.id = parsedResponse.data?.id as string;
    forgotPasswordState.recoveryUuid = parsedResponse.data?.recoveryUuid;

    // set "wallet confirmation" as next action
    forgotPasswordPendingAction.value =
      ForgotPasswordAction.WALLET_CONFIRMATION;

    return parsedResponse.data;
  };
  /**
   * END INITIATE ACCOUNT RECOVERY
   */

  /**
   * FETCH WALLET CONFIRMATION DETAIL
   */
  const fetchWalletConfirmationDetail = async () => {
    await handleRefetchWalletConfirmationDetail({
      input: {
        recoveryUuid: forgotPasswordState.recoveryUuid,
      },
    });
  };
  /**
   * END FETCH WALLET CONFIRMATION DETAIL
   */

  /**
   * CONFIRM WALLET
   */
  const confirmWallet = async () => {
    try {
      await handleConfirmWallet({
        walletConfirmationAttemptId: walletConfirmationDetail.value
          ?.walletConfirmationAttempt.id as string,
        recoveryUuid: forgotPasswordState.recoveryUuid,
      });

      // set "set password" as next action
      forgotPasswordPendingAction.value = ForgotPasswordAction.SET_PASSWORD;
    } catch (error) {
      // throw error to parent, this only has catch so forgotPasswordPendingAction will work properly
      throw new Error(String(error));
    }
  };
  /**
   * END CONFIRM WALLET
   */

  const { mutate: setPasswordMutate, loading: setNewPasswordLoading } =
    useCustomMutation<SetPassword, SetPasswordVariables>(setPasswordGql);
  const setNewPassword = async ({
    newPassword,
  }: {
    newPassword: SetPasswordInput["newPassword"];
  }) => {
    const setNewPasswordResponse = await setPasswordMutate({
      input: { recoveryUuid: forgotPasswordState.recoveryUuid, newPassword },
    });

    const parsedResponse =
      parseGqlResponse<SetPassword_setPassword_GenericSuccess>(
        "GenericSuccess",
        setNewPasswordResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("setNewPassword:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !setNewPasswordResponse) {
      throw new Error("SetNewPassword error");
    }

    return parsedResponse.data;
  };

  return {
    forgotPasswordPendingAction,
    initForgotPassword,
    initForgotPasswordLoading,
    fetchWalletConfirmationDetail,
    walletConfirmationDetail,
    walletConfirmationDetailLoading,
    confirmWalletLoading,
    confirmWallet,
    setNewPassword,
    setNewPasswordLoading,
  };
};
